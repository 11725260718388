import { useAppContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import React from "react";
import { LiaUserCircleSolid } from "react-icons/lia";

const UserButton = () => {
  const navigate = useNavigate();
  const { state } = useAppContext();
  return (
    <div
      onClick={() => {
        if (state?.token) {
          navigate("/profile?t=1");
        } else {
          navigate("/login-register");
        }
      }}
    >
      <LiaUserCircleSolid
        size={30}
        className="duration-300 hover:text-app-green hover:cursor-pointer"
      />
    </div>
  );
};

export default React.memo(UserButton);

import axios from "axios";
import { toast } from "react-toastify";

const ESALE_BASE_URL = "https://esale.erdenetmc.mn/api/api";

export function ESALE() {
  return createInstance(ESALE_BASE_URL);
}

const createInstance = (baseURL) => {
  const headers = {
    "Content-Type": "application/json,",
  };
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : null;
  if (token != null) {
    Object.assign(headers, {
      Authorization: "Bearer " + token,
    });
  }

  let api = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    headers,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.message !== "Network Error") {
        if (error.response.status === 400) {
          if (error.response.data.message === "No user found") {
            toast.warn("Бүртгэлгүй хэрэглэгч.");
          } else {
            toast.warn(error.response.data.message);
          }
        }
        if (error.response.status === 401) {
          localStorage.clear();
          throw error;
        } else {
          throw error;
        }
      } else {
        toast.warn(error.response.data.message);
        throw error;
      }
    }
  );
  return api;
};

export async function post(url, json) {
  const response = await ESALE().post(url, { ...json });
  return response?.data;
}
export async function put(url, json) {
  const response = await ESALE().put(url, { ...json });
  if (response?.data?.status_code === 1) {
    return response?.data;
  } else {
    toast.warn(response?.data?.response);
    return;
  }
}
export async function get(url, params) {
  const response = await ESALE().get(url, { params: { ...params } });
  if (response?.data?.status_code === 1) {
    return response?.data;
  } else {
    toast.warn(response?.data?.response);
    return [];
  }
}
export async function trash(url, params) {
  const response = await ESALE().delete(url, { params: { ...params } });
  if (response?.data?.status_code === 1) {
    return response?.data;
  } else {
    toast.warn(response?.data?.response);
    return [];
  }
}

import React, { useEffect, useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../contexts/GlobalContext";
import { post } from "../api/request";

const Login = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const redirect_url = searchParams.get("redirect_url");

  const { state, dispatch } = useAppContext();
  const [loginstate, setState] = useState({
    rememberMe: false,
    submit: false,
    show_password: "",
    phone: "",
    password: "",
    loading: false,
  });

  useEffect(() => {
    const rememberMe = JSON.parse(localStorage.getItem("rememberMe"));
    if (rememberMe) {
      setState({
        ...loginstate,
        rememberMe: true,
        phone: rememberMe?.phone,
        password: rememberMe?.password,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Login = async () => {
    setState({ ...loginstate, submit: true });
    if (loginstate?.phone?.length === 8 && loginstate?.password) {
      setState({ ...loginstate, loading: true });
      try {
        const register = await post("/user/login", {
          phone: loginstate?.phone,
          password: loginstate?.password,
        });
        if (register?.status_code) {
          setState({
            ...loginstate,
            rememberMe: false,
            submit: false,
            show_password: "",
            phone: "",
            password: "",
          });
          dispatch({
            type: "STATE",
            data: {
              token: register?.access_token,
              user_reload: state?.user_reload + 1,
            },
          });
          localStorage.setItem("token", register?.access_token);
          toast.success("Амжилттай нэвтэрлээ.");
          navigate(redirect_url ? redirect_url : "/profile?t=1");
        } else {
          toast.warn(register?.response);
        }
      } catch (error) {
        toast.warn(error?.message);
      } finally {
        setState({ ...loginstate, loading: false });
      }
    }
  };

  return (
    <div className="bg-[#E8E8E8] flex items-center justify-center flex-col p-10 select-none">
      <div className="text-2xl md:text-3xl font-semibold text-gray-800">
        Нэвтрэх
      </div>
      <div className="text-sm text-gray-600 font-light mt-2">
        Доорх мэдээллийг бөглөж нэвтэрнэ үү.
      </div>
      <input
        type="text"
        placeholder="Утасны дугаар"
        className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
          loginstate?.phone?.length !== 8 && loginstate?.submit
            ? "border-red-500"
            : ""
        }`}
        value={loginstate?.phone}
        onChange={(e) => {
          const value = e.target.value;
          const reg = /^-?\d+$/;
          if (value === "" || (reg.test(value) && value.length <= 8)) {
            setState({ ...loginstate, phone: e.target.value });
          }
        }}
      />
      <div className="mt-4 w-full relative">
        <input
          type={loginstate?.show_password ? "text" : "password"}
          placeholder="Нууц үг оруулна уу"
          className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm ${
            !loginstate?.password && loginstate?.submit ? "border-red-500" : ""
          }`}
          value={loginstate?.password}
          onChange={(e) =>
            setState({ ...loginstate, password: e.target.value })
          }
        />
        <RiEyeCloseLine
          className={`absolute right-2 top-1/2 -translate-y-1/2 text-xs w-5 h-5 duration-300 hover:cursor-pointer ${
            loginstate?.show_password && "text-app-green"
          }`}
          onClick={() =>
            setState({
              ...loginstate,
              show_password: !loginstate?.show_password,
            })
          }
        />
      </div>
      <div className="flex items-center justify-between mt-4 w-full">
        <div className="select-none flex items-center justify-center gap-2">
          <input
            type="checkbox"
            className="w-4 h-4 duration-300 hover:cursor-pointer"
            id="rememberMe"
            checked={loginstate?.rememberMe}
            onChange={(e) => {
              if (e.target.checked) {
                if (loginstate?.phone && loginstate?.password) {
                  setState({ ...loginstate, rememberMe: true });
                  localStorage.setItem(
                    "rememberMe",
                    JSON.stringify({
                      phone: loginstate?.phone,
                      password: loginstate?.password,
                    })
                  );
                }
              } else {
                setState({ ...loginstate, rememberMe: false });
                localStorage.removeItem("rememberMe");
              }
            }}
          />
          <label
            className="text-sm text-gray-700 duration-300 hover:cursor-pointer"
            htmlFor="rememberMe"
          >
            Сануулах
          </label>
        </div>
        <div className="text-sm text-gray-700 duration-300 hover:underline hover:cursor-pointer">
          Нууц үгээ мартсан уу?
        </div>
      </div>
      <div className="flex items-center justify-end mt-6 w-full">
        <div
          className="px-4 py-2.5 font-semibold self-end select-none bg-gray-900 text-white duration-300 hover:bg-app-green hover:cursor-pointer"
          onClick={() => Login()}
        >
          Нэвтрэх
        </div>
      </div>
    </div>
  );
};

export default React.memo(Login);

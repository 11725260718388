import React from "react";
import _ from "lodash";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import { useAppContext } from "../contexts/GlobalContext";
import { Link } from "react-router-dom";

const Item = ({ data }) => {
  const { state, dispatch } = useAppContext();

  var find_basket = state?.basket?.find(
    (el) => el?.public_id === data?.public_id
  );

  return (
    <Link
      to={`/products/${data?.public_id}`}
      className="flex items-center flex-col border shadow-md cursor-pointer animate-fade"
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    >
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper w-full h-[100px] sm:h-[150px] md:h-[100px] lg:h-[150px]"
      >
        {_.map(data?.files, (item, index) => {
          return (
            <SwiperSlide key={"image_" + index + "_" + data?.public_id}>
              <img
                src={"https://" + item}
                alt="Зураггүй"
                width={500}
                height={500}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="py-2 sm:py-4 px-2 sm:px-10 md:py-2 md:px-2 w-full">
        <div className="whitespace-nowrap overflow-hidden text-xs text-gray-500 text-left sm:text-center">
          {data?.category_name}
        </div>
        <div className="text-xs sm:text-sm text-gray-800 text-center font-semibold mt-2 sm:px-10 md:px-2 min-h-[40px] h-[40px] overflow-hidden">
          {data?.title}
        </div>
        <div className="text-xs text-gray-600 text-center mt-2 px-10 flex items-center justify-center gap-4">
          <div>Үлдэгдэл:</div>
          <div className="lowercase">
            {data?.stock}
            {data?.unit_name}
          </div>
        </div>
        <div className="text-app-green font-bold mt-2 text-center">
          {data?.price && data?.price?.toLocaleString()}₮
        </div>
        <div className="flex justify-center">
          <div
            className={`px-4 py-1 border font-light rounded-md mt-2 text-center duration-300 ${
              find_basket
                ? "text-red-500 border-red-500 hover:bg-red-500"
                : "text-app-green border-app-green hover:bg-app-green"
            } hover:text-white hover:cursor-pointer`}
            onClick={(event) => {
              event.preventDefault(); // Prevent the default link behavior
              event.stopPropagation(); // Stop event propagation

              const { stock, public_id } = data;
              const isItemInBasket = find_basket;
              if (stock >= 1) {
                let updatedBasket;
                if (isItemInBasket) {
                  const filteredBasket = _.filter(
                    state?.basket,
                    (el) => el?.public_id !== public_id
                  );
                  updatedBasket = filteredBasket;
                } else {
                  const newItem = { ...data, quantity: 1 };
                  updatedBasket = [...state?.basket, newItem];
                }
                dispatch({
                  type: "STATE",
                  data: {
                    basket: updatedBasket,
                  },
                });
                localStorage.setItem(
                  "basket_list",
                  JSON.stringify(updatedBasket)
                );
              }
            }}
          >
            {find_basket ? "Сагснаас хасах" : "Сагслах"}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(Item);

import React from "react";
import { useAppContext } from "../contexts/GlobalContext";

const Toolbar = ({ length }) => {
  const { state, dispatch } = useAppContext();
  return (
    <div className="shop_toolbar_wrapper flex-column flex-md-row mb-5 animate-fade">
      <div className="shop-top-bar-left mb-md-0 mb-2">
        <div className="shop-top-show">
          <span>{length ? length : 0} илэрц харуулж байна.</span>
        </div>
      </div>
      <div className="shop-top-bar-right">
        <div className="shop-short-by mr-4">
          <select
            className="border outline-none px-4 py-1 hover:border-app-green active:border-app-green"
            onChange={(e) => {
              dispatch({
                type: "STATE",
                data: {
                  order_type: parseInt(e.target.value),
                },
              });
            }}
            value={state?.order_type}
          >
            <option value={1}>Шинэ нь эхэндээ</option>
            <option value={2}>Хуучин нь эхэндээ</option>
            <option value={3}>Үнэ өсөхөөр</option>
            <option value={4}>Үнэ буурхаар</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Toolbar);

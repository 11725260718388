import ProfileInfo from "../components/ProfileInfo";
import ProfileOrder from "../components/ProfileOrder";
import { useAppContext } from "../contexts/GlobalContext";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { get } from "../api/request";

const Profile = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const redirect_url = searchParams.get("t");
  const { state, dispatch, Logout } = useAppContext();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const tabs = [
    { id: 1, label: "Миний мэдээлэл" },
    { id: 2, label: "Миний захиалга" },
    // { id: 3, label: "asdasd" },
    // { id: 4, label: "zxczxc" },
  ];

  const fetchdata = async () => {
    setLoading(true);
    try {
      const orders = await get(`/user/orders`);
      if (orders?.status_code) {
        setOrders(orders?.response);
      } else {
        toast.warn(orders?.response);
      }
    } catch (error) {
      if (_.includes([401, 422], error?.response?.status)) {
        localStorage.clear();
        dispatch({
          type: "STATE",
          data: {
            token: null,
          },
        });
        navigate("/");
      }
      toast.warn(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.token) {
      fetchdata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.token, refresh]);

  const Render = () => {
    switch (parseInt(redirect_url)) {
      case 1:
        return <ProfileInfo setLoading={setLoading} />;
      case 2:
        return (
          <ProfileOrder
            orders={orders}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        );
      default:
        break;
    }
  };

  return (
    <section className="max-w-8xl mx-auto px-2 mt-20 sm:px-8 2xl:px-0">
      <div className="py-10 grid grid-cols-1 lg:grid-cols-4 gap-5">
        <div className="p-8 rounded-xl border shadow-md">
          <i className="fa fa-user-circle-o text-7xl text-app-green" />
          <div className="font-semibold text-center pt-2">
            {state?.info?.lastname}
          </div>
          <div className="font-normal text-xs text-gray-600 text-center pb-4">
            {state?.info?.phone}
          </div>
          <div className="flex flex-col">
            {_.map(tabs, (tab) => (
              <Link
                to={`/profile?t=${tab?.id}`}
                key={tab?.id}
                className={`border-b p-2 text-sm rounded-md cursor-pointer duration-200 hover:font-medium ${
                  parseInt(redirect_url) === tab?.id
                    ? "text-white bg-app-green"
                    : "text-gray-700 bg-white"
                }`}
              >
                {tab?.label}
              </Link>
            ))}
            <div
              className={`border-b p-2 text-sm rounded-md cursor-pointer duration-200 hover:font-medium text-gray-700 bg-white`}
              onClick={() => Logout()}
            >
              Гарах
            </div>
          </div>
        </div>
        <div className="lg:col-span-3">
          <div className="p-8 rounded-xl border shadow-md relative">
            <div
              className={`${
                loading ? "absolute" : "hidden"
              } top-0 left-0 w-full h-full rounded-xl bg-black/30`}
            >
              <div className="flex items-center justify-center h-full text-white font-light">
                Уншиж байна...
              </div>
            </div>
            <div className="text-xl font-semibold tracking-wider">
              {tabs?.find((tab) => tab?.id === parseInt(redirect_url))?.label}
              {Render()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;

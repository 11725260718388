import React, { useEffect, useState } from "react";
import Item from "../components/Item";
import Quantity from "../components/Quantity";
import ProductImages from "../components/productImages";
import _ from "lodash";
import { useParams } from "react-router-dom";
import * as API from "../api/request";
import { toast } from "react-toastify";

const Products = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [list, setList] = useState([]);

  const fetchdata = async () => {
    try {
      if (id) {
        const data = await API.get(`/public/product/${id}`);
        setData(data?.response || {});
      }
    } catch (error) {
      toast.warn(error?.message);
    }
  };

  const fetchlist = async () => {
    try {
      if (data?.category_public_id && id) {
        const res = await API.get("/public/product", {
          page: 1,
          per_page: 4,
          cat_id: data?.category_public_id,
        });
        setList(_.filter(res?.list, (el) => el?.public_id !== id));
      }
    } catch (error) {
      toast.warn(error?.message);
    }
  };

  useEffect(() => {
    fetchlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.category_public_id, id]);

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // const data = await getProduct(params?.id);
  // const datas = await getProducts();
  // const [product, products] = await Promise.all([data, datas]);
  // const item = product?.response;

  // const filter_data = _.filter(products?.list, (item) => {
  //   return (
  //     data?.category_public_id === data?.category_public_id &&
  //     data?.public_id !== data?.public_id
  //   );
  // });

  if (!data) {
    return (
      <section className="max-w-8xl mx-auto px-2 mt-20 sm:px-8 2xl:px-0">
        <div className="flex items-center justify-center">
          Мэдээлэл хоосон байна.
        </div>
      </section>
    );
  }
  return (
    <section className="max-w-8xl mx-auto px-2 mt-20 sm:px-8 2xl:px-0">
      <div className="pt-10 md:pt-20 grid grid-cols-1 md:grid-cols-5 gap-0 md:gap-6">
        <div className="col-span-2 w-full h-full md:sticky md:top-28 md:left-0 animate-fade">
          {data?.files?.length > 0 && <ProductImages data={data?.files} />}
        </div>
        <div className="col-span-3 w-full h-full mt-6 md:mt-0 animate-fade">
          <div className="text-base font-semibold mt-2 md:mt-0 md:text-2xl">
            {data?.title}
          </div>
          <div className="text-app-green font-bold mt-2 md:mt-4 md:text-2xl">
            {data?.price && data?.price?.toLocaleString()}₮
          </div>
          <div className="text-gray-600 text-sm mt-2 md:mt-4 md:text-base">
            {data?.description}
          </div>
          <div className="flex items-center flex-wrap text-gray-600 text-sm mt-2 md:mt-4 md:text-base">
            <div className="mr-2">Үлдэгдэл:</div>
            <div className="mr-2 font-semibold">{data?.stock}</div>
          </div>
          <div className="flex items-center flex-wrap text-gray-600 text-sm mt-2 md:mt-4 md:text-base">
            <div className="mr-2">Ангилал:</div>
            <div className="mr-2 font-semibold">{data?.category_name}</div>
          </div>
          <div className="flex items-center flex-wrap text-gray-600 text-sm mt-2 md:mt-4 md:text-base">
            <div className="mr-2">Брэнд:</div>
            <div className="mr-2 font-semibold">{data?.brand_name}</div>
          </div>
          <Quantity item={data} />
        </div>
      </div>
      <div className="pb-10 md:pb-20">
        <div className="section-title animate-fade">
          <h2 className="title pb-3 pt-10">Төстэй бараанууд</h2>
          <span />
          <div className="title-border-bottom" />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 sm:gap-8 mb-10 scroll-smooth">
          {_.map(list, (item, index) => {
            return <Item data={item} key={"filter_data_" + index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default React.memo(Products);

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import Router from "./Router";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <ToastContainer />
        <Router />
      </GlobalContextProvider>
    </BrowserRouter>
  );
};

export default React.memo(App);

import LoginForm from "../components/Login";
import RegisterForm from "../components/Register";
import React from "react";

const Login = () => {
  return (
    <section className="max-w-8xl mx-auto px-2 mt-20 sm:px-8 2xl:px-0">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 py-10 md:py-20">
        <div>
          <LoginForm />
        </div>
        <div>
          <RegisterForm />
        </div>
      </div>
    </section>
  );
};

export default React.memo(Login);

import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#f6f7f7] 2xl:px-0">
      {/* <div className="max-w-8xl mx-auto py-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col items-center justify-center">
            <div className="text-lg mb-4 text-gray-600">Contact Us</div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-lg mb-4 text-gray-600">Information</div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-lg mb-4 text-gray-600">My Account</div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
            <div className="mb-4 text-gray-500">
              asd asd asd asd asd asd asd asd
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full bg-[#e7e8e8] flex items-center justify-center">
        <div className="py-5 font-light">
          © {new Date().getFullYear()} Эрдэнэт үйлдвэр ТӨҮГ ХМТА цех
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);

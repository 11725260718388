import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "../components/Layout";
import ProductList from "../pages/ProductList";
import Products from "../pages/Products";
import Basket from "../pages/Basket";
import LoginRegister from "../pages/LoginRegister";
import Profile from "./Profile";

const Router = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Layout>
            <ProductList />
          </Layout>
        }
      />
      <Route
        exact
        path="/basket"
        element={
          <Layout>
            <Basket />
          </Layout>
        }
      />
      <Route
        exact
        path="/login-register"
        element={
          <Layout>
            <LoginRegister />
          </Layout>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <Layout>
            <Profile />
          </Layout>
        }
      />
      <Route
        exact
        path="/products/:id"
        element={
          <Layout>
            <Products />
          </Layout>
        }
      />
    </Routes>
  );
};

export default React.memo(Router);

import React, { useEffect } from "react";
import { useAppContext } from "../contexts/GlobalContext";

const Search = ({ setValue, value }) => {
  const { dispatch } = useAppContext();

  useEffect(() => {
    if (!value) {
      dispatch({
        type: "STATE",
        data: {
          search: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="widget-list mb-5">
      <h3 className="widget-title mb-4">Хайлт</h3>
      <div className="search-box">
        <input
          type="text"
          className="form-control outline-none"
          placeholder="Хайх..."
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value !== "") {
              dispatch({
                type: "STATE",
                data: {
                  search: e.target.value,
                },
              });
            }
          }}
        />
        <div
          className="bg-app-green text-white w-[40px] h-[45px] flex items-center justify-center duration-300 hover:cursor-pointer"
          onClick={() => {
            if (value !== "") {
              dispatch({
                type: "STATE",
                data: {
                  search: value,
                },
              });
            }
          }}
        >
          <i className="fa fa-search duration-300 hover:scale-110" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Search);

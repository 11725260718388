import { useAppContext } from "../contexts/GlobalContext";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { post, put } from "../api/request";

const ProfileInfo = ({ setLoading }) => {
  const { state, dispatch } = useAppContext();
  const [submit, setSubmit] = useState(false);
  const [psubmit, setpsubmit] = useState(false);
  const [password, setPassword] = useState({
    oldpassword: "",
    password: "",
  });

  const update = async () => {
    setSubmit(true);
    let check = false;
    if (state?.info?.is_company) {
      if (
        state?.info?.lastname &&
        state?.info?.register &&
        state?.info?.email &&
        state?.info?.address &&
        state?.token
      ) {
        check = true;
      }
    } else {
      if (
        state?.info?.firstname &&
        state?.info?.lastname &&
        state?.info?.register &&
        state?.info?.email &&
        state?.info?.address &&
        state?.token
      ) {
        check = true;
      }
    }
    if (check) {
      setLoading(true);
      const res = await post("/user/detail", {
        firstname: state?.info?.firstname,
        lastname: state?.info?.lastname,
        register: state?.info?.register,
        email: state?.info?.email,
        address: state?.info?.address,
      });
      if (res?.status_code) {
        toast.success("Мэдээлэл амжилттай шинэчлэгдлээ.");
      } else {
        toast.warn(res?.response);
      }
      setSubmit(false);
      setLoading(false);
    }
  };

  const passwordChange = async () => {
    setpsubmit(true);
    if (password?.oldpassword && password?.password && state?.token) {
      setLoading(true);
      try {
        const res = await put("/user/detail", {
          oldpassword: password?.oldpassword,
          password: password?.password,
        });

        if (res?.status_code) {
          setPassword({ oldpassword: "", password: "" });
          toast.success("Нууц үг амжилттай шинэчлэгдлээ.");
        } else {
          toast.warn(res?.response);
        }
      } catch (error) {
        toast.warn(error?.message);
      } finally {
        setpsubmit(false);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 gap-4 mt-3">
        {!state?.info?.is_company && (
          <input
            type="text"
            placeholder="Овог"
            className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
              submit && !state?.info?.firstname ? "border-red-500" : ""
            }`}
            value={state?.info?.firstname || ""}
            onChange={(e) => {
              dispatch({
                type: "STATE",
                data: {
                  info: {
                    ...state?.info,
                    firstname: e.target.value,
                  },
                },
              });
            }}
          />
        )}

        <input
          type="text"
          placeholder="Нэр"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            submit && !state?.info?.lastname ? "border-red-500" : ""
          }`}
          value={state?.info?.lastname || ""}
          onChange={(e) => {
            dispatch({
              type: "STATE",
              data: {
                info: {
                  ...state?.info,
                  lastname: e.target.value,
                },
              },
            });
          }}
        />
        <input
          type="text"
          placeholder="Регистер"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            submit && !state?.info?.register ? "border-red-500" : ""
          }`}
          value={state?.info?.register || ""}
          onChange={(e) => {
            dispatch({
              type: "STATE",
              data: {
                info: {
                  ...state?.info,
                  register: e.target.value,
                },
              },
            });
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 mt-3">
        <input
          type="text"
          placeholder="Утас"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            submit && !state?.info?.phone ? "border-red-500" : ""
          }`}
          disabled={true}
          value={state?.info?.phone || ""}
          onChange={(e) => {
            dispatch({
              type: "STATE",
              data: {
                info: {
                  ...state?.info,
                  phone: e.target.value,
                },
              },
            });
          }}
        />
        <input
          type="email"
          placeholder="Е-Мэйл"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            submit && !state?.info?.email ? "border-red-500" : ""
          }`}
          value={state?.info?.email || ""}
          onChange={(e) => {
            dispatch({
              type: "STATE",
              data: {
                info: {
                  ...state?.info,
                  email: e.target.value,
                },
              },
            });
          }}
        />
        <textarea
          type="text"
          placeholder="Хаяг"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            submit && !state?.info?.address ? "border-red-500" : ""
          }`}
          value={state?.info?.address || ""}
          onChange={(e) => {
            dispatch({
              type: "STATE",
              data: {
                info: {
                  ...state?.info,
                  address: e.target.value,
                },
              },
            });
          }}
        />
        <div
          className="px-4 h-full flex items-center rounded justify-center text-sm font-light select-none bg-app-green/80 text-white duration-300 hover:bg-app-green hover:cursor-pointer"
          onClick={() => {
            update();
          }}
        >
          Шинэчлэх
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-3 py-3 border-t">
        <input
          type="password"
          placeholder="Хуучин нууц үг"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            psubmit && !password?.oldpassword ? "border-red-500" : ""
          }`}
          value={password?.oldpassword || ""}
          onChange={(e) =>
            setPassword({ ...password, oldpassword: e.target.value })
          }
        />
        <input
          type="password"
          placeholder="Шинэ нууц үг"
          className={`outline-none font-normal p-2 border duration-300 focus:border-app-green w-full text-sm ${
            psubmit && !password?.password ? "border-red-500" : ""
          }`}
          value={password?.password || ""}
          onChange={(e) =>
            setPassword({ ...password, password: e.target.value })
          }
        />
        <div
          className="px-4 h-full flex items-center rounded justify-center text-sm font-light select-none bg-app-green/80 text-white duration-300 hover:bg-app-green hover:cursor-pointer"
          onClick={() => {
            passwordChange();
          }}
        >
          Нууц үг солих
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfileInfo);

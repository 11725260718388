import React, { useState } from "react";
import Search from "./Search";
import _ from "lodash";
import { useAppContext } from "../contexts/GlobalContext";

const SidebarFilter = () => {
  const { state, dispatch } = useAppContext();
  const [value, setValue] = useState("");
  return (
    <aside className="sidebar_widget mt-lg-0 text-gray-600 top-24 animate-fade">
      <div className="widget_inner">
        <Search setValue={setValue} value={value} />
        <div className="widget-list mb-10">
          <div className="mb-4 flex">
            <div
              onClick={() => {
                setValue("");
                dispatch({
                  type: "STATE",
                  data: {
                    brand_id: null,
                    cat_id: null,
                    search: null,
                  },
                });
              }}
              className="border py-1.5 px-4 rounded-3xl cursor-pointer text-sm duration-300 hover:bg-app-green hover:text-white"
            >
              <i className="fa fa-refresh mr-2" />
              Шүүртүүр цэвэрлэх
            </div>
          </div>
          <h3 className="widget-title">Ангилал</h3>
          <div className="sidebar-body pt-2 flex flex-col h-[calc(100vh-350px)] scrollbar">
            {_.map(
              _.orderBy(state?.categories, ["name"], ["asc"]),
              (el, index) => {
                return (
                  <div
                    key={"category_" + index}
                    onClick={() => {
                      if (state.cat_id === el?.public_id) {
                        dispatch({
                          type: "STATE",
                          data: {
                            cat_id: null,
                          },
                        });
                      } else {
                        dispatch({
                          type: "STATE",
                          data: {
                            cat_id: el?.public_id,
                          },
                        });
                      }
                    }}
                    className="select-none text-sm cursor-pointer my-1 px-4"
                  >
                    <div
                      className={
                        state?.cat_id === el?.public_id
                          ? "text-app-green font-medium"
                          : ""
                      }
                    >
                      {el?.name}
                    </div>
                  </div>
                );
              }
            )}
            <h3 className="widget-title py-4">Брэнд</h3>
            {_.map(state?.brands, (el, index) => {
              return (
                <div
                  key={"brand_" + index}
                  onClick={() => {
                    if (state.brand_id === el?.public_id) {
                      dispatch({
                        type: "STATE",
                        data: {
                          brand_id: null,
                        },
                      });
                    } else {
                      dispatch({
                        type: "STATE",
                        data: {
                          brand_id: el?.public_id,
                        },
                      });
                    }
                  }}
                  className="select-none text-sm cursor-pointer my-1 px-4"
                >
                  <div
                    className={
                      state?.brand_id === el?.public_id
                        ? "text-app-green font-medium"
                        : ""
                    }
                  >
                    {el?.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default React.memo(SidebarFilter);

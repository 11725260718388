import React, { createContext, useReducer, useContext, useEffect } from "react";
import * as API from "../api/request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MyContext = createContext();
const initialState = {
  info: {},
  basket: [],
  token: null,
  categories: [],
  brands: [],
  list: [],
  per_page: 48,
  page_count: 1,
  search: null,
  brand_id: null,
  cat_id: null,
  loading: false,
  order_type: 1,
  payment_type: 1,
  user_reload: 0,
  payment_modal: false,
  order: {},
  qpay: {},
  url_open: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "STATE":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const fetchdata = async () => {
    const categories = await API.get(`/public/category`);
    const brands = await API.get(`/public/brand`);
    dispatch({
      type: "STATE",
      data: {
        categories: categories?.response || [],
        brands: brands?.response || [],
      },
    });
  };

  useEffect(() => {
    fetchdata();
    const list = JSON.parse(localStorage.getItem("basket_list"));

    if (list)
      dispatch({
        type: "STATE",
        data: {
          basket: list,
        },
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      API.get(`/user/detail`)
        .then((detail) => {
          if (detail?.status_code) {
            dispatch({
              type: "STATE",
              data: {
                info: detail?.response,
                token: token,
              },
            });
          } else {
            toast.warn(detail?.response);
          }
        })
        .catch((err) => {
          toast.warn(err?.response?.data?.message);
        });
    }
  }, [state?.user_reload]);

  const Logout = async () => {
    const res = await API.trash("/user/logout");
    if (res?.status_code === 1) {
      localStorage.clear();
      dispatch({
        type: "STATE",
        data: {
          token: null,
        },
      });
      navigate("/");
    } else {
      toast.warn(res?.response);
    }
  };

  useEffect(() => {
    dispatch({
      type: "STATE",
      data: {
        loading: true,
      },
    });
    API.get("/public/product", {
      page: 1,
      per_page: state?.per_page,
      searchtext: state?.search,
      brand_id: state?.brand_id,
      cat_id: state?.cat_id,
      order_type: state?.order_type,
    })
      .then((data) => {
        dispatch({
          type: "STATE",
          data: {
            page_count: data?.page_count || 1,
            list: data?.list || [],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch({
          type: "STATE",
          data: {
            loading: false,
          },
        });
      });
  }, [
    state?.brand_id,
    state?.cat_id,
    state?.order_type,
    state?.per_page,
    state?.search,
  ]);

  return (
    <MyContext.Provider value={{ state, dispatch, Logout }}>
      {children}
    </MyContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error(
      "useAppContext must be used within a GlobalContextProvider"
    );
  }
  return context;
};

export { GlobalContextProvider, useAppContext };

import { FiShoppingBag } from "react-icons/fi";
import Basket from "./Basket";
import UserButton from "./UserButton";
import { Link } from "react-router-dom";
import React from "react";

const Header = () => {
  return (
    <header className="fixed top-0 left-0 w-full h-20 border-b shadow-sm flex items-center bg-white z-30 select-none">
      <div className="w-full flex items-center justify-between max-w-8xl mx-auto px-2 sm:px-8 2xl:px-0 text-gray-600">
        <Link
          to="/"
          className="font-bold tracking-widest text-2xl whitespace-nowrap"
        >
          <span className="text-app-green">E</span>-SALE
        </Link>
        <div className="flex items-center gap-4">
          <UserButton />
          <Link
            to="/basket"
            className="relative"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <FiShoppingBag
              size={26}
              className="duration-300 hover:text-app-green hover:cursor-pointer"
            />
            <Basket />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);

import React from "react";
import { useAppContext } from "../contexts/GlobalContext";

const Basket = () => {
  const { state } = useAppContext();
  return (
    <div className="w-6 h-6 rounded-full flex items-center justify-center absolute -top-3 -right-3 bg-app-green text-white text-xs font-semibold">
      {state?.basket?.length}
    </div>
  );
};

export default React.memo(Basket);

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../contexts/GlobalContext";
import * as API from "../api/request";
import { DatePicker, Modal } from "antd";
import moment from "moment";
import { PiWalletFill } from "react-icons/pi";
import { IoQrCodeOutline } from "react-icons/io5";
import OrderModal from "../components/OrderModal";

const Page = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [payment_type, setPayment_type] = useState(1);

  const [state_a, setState] = useState({
    order_id: null,
    modal: false,
    submit: false,
    car_mark: "",
    car_number: "",
    request_date: null,
    request: [],
  });

  useEffect(() => {
    let count = 0;
    _.map(state?.basket, (el) => {
      if (el?.price && el?.quantity) {
        count += el?.price * el?.quantity;
      }
    });
    setTotal(count);
  }, [state?.basket]);

  const save = async () => {
    setState((old) => ({ ...old, submit: true }));
    if (state_a?.car_mark && state_a?.car_number && state_a?.request_date) {
      if (state?.basket?.length > 0) {
        try {
          setLoading(true);
          const join_data = _.map(
            state?.basket,
            (el) => el?.public_id + "*" + el?.quantity
          );

          const response = await API.post(`/user/orders`, {
            products: join_data?.toString(),
            type: payment_type,
          });

          if (response?.status === 401) {
            window.localStorage.removeItem("token");
            navigate("/login-register");
          }

          if (response?.status_code && response?.order_id) {
            const car = await API.post(
              `/user/orders/${response?.order_id}/request`,
              {
                car_mark: state_a?.car_mark,
                car_number: state_a?.car_number,
                request_date: moment(state_a?.request_date?.$d).format(
                  "YYYYMMDD"
                ),
              }
            );
            const order = await API.get(`/user/orders/${response?.order_id}`);
            if (car?.status_code && order?.status_code) {
              toast.success(
                "Захиалгын хүсэлт амжилттай илгээгдлээ. Та захиалгаа миний захиалга хэсгээс харна уу."
              );
              localStorage.removeItem("basket_list");
              setState((old) => ({ ...old, modal: false }));
              dispatch({
                type: "STATE",
                data: {
                  order: { ...order, public_id: order?.order?.public_id },
                  payment_modal: true,
                  basket: [],
                },
              });
            } else {
              toast.warn(car?.response);
            }
          } else {
            toast.warn(response?.response);
          }
        } catch (error) {
          toast.warn(error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      } else {
        toast.info("Сагс хоосон байна.");
      }
    }
  };

  const onChange = (date, dateString) => {
    setState((old) => ({
      ...old,
      request_date: date,
    }));
  };

  return (
    <section className="max-w-8xl mx-auto px-2 mt-20 sm:px-8 2xl:px-0">
      <OrderModal />
      <Modal
        title="Захиалагчийн мэдээлэл"
        open={state_a?.modal}
        footer={false}
        onCancel={() => {
          setState((old) => ({ ...old, modal: false }));
        }}
        centered
        width={1000}
      >
        <div>
          <div
            className={`${
              loading ? "absolute" : "hidden"
            } top-0 left-0 w-full h-full z-50 bg-black/30`}
          >
            <div className="flex items-center justify-center h-full text-white font-light">
              Уншиж байна...
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            {!state?.info?.is_company && (
              <div>
                <div className="font-semibold text-gray-700">Овог:</div>
                <input
                  type="text"
                  className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm`}
                  value={state?.info?.firstname}
                  readOnly={true}
                />
              </div>
            )}
            <div>
              <div className="font-semibold text-gray-700">
                {state?.info?.is_company ? "Компанийн нэр" : "Нэр:"}
              </div>
              <input
                type="text"
                className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm`}
                value={state?.info?.lastname}
                readOnly={true}
              />
            </div>
            <div>
              <div className="font-semibold text-gray-700">Утасны дугаар:</div>
              <input
                type="text"
                className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm`}
                value={state?.info?.phone}
                readOnly={true}
              />
            </div>
            <div>
              <div className="font-semibold text-gray-700">И-Мэйл хаяг:</div>
              <input
                type="text"
                className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm`}
                value={state?.info?.email}
                readOnly={true}
              />
            </div>
          </div>
          <div
            className="underline cursor-pointer flex items-center justify-end"
            onClick={() => {
              navigate("/profile?t=1");
            }}
          >
            Захиалагчийн мэдээлэл өөрчлөх
          </div>
          <div className="mt-4 font-semibold text-[16px]">Машины мэдээлэл</div>
          <input
            type="text"
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
              !state_a?.car_mark && state_a?.submit ? "border-red-500" : ""
            }`}
            placeholder="Машины марк"
            value={state_a?.car_mark}
            onChange={(e) => {
              setState((old) => ({ ...old, car_mark: e.target.value }));
            }}
          />
          <input
            type="text"
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
              !state_a?.car_number && state_a?.submit ? "border-red-500" : ""
            }`}
            placeholder="Машины дугаар"
            value={state_a?.car_number}
            onChange={(e) => {
              setState((old) => ({ ...old, car_number: e.target.value }));
            }}
          />
          <DatePicker
            value={state_a?.request_date}
            onChange={onChange}
            format={"YYYY.MM.DD"}
            className={`mt-4 w-full rounded-none text-gray-700 hover:border-gray-200 ${
              !state_a?.request_date && state_a?.submit ? "border-red-500" : ""
            }`}
            placeholder="Очих өдөр сонгох"
          />
          <div className="mt-4">
            <div
              className="px-4 py-2.5 h-full flex items-center rounded justify-center text-sm font-light select-none bg-app-green/80 text-white duration-300 hover:bg-app-green hover:cursor-pointer tracking-wider"
              onClick={() => {
                save();
              }}
            >
              Үргэлжлүүлэх
            </div>
          </div>
        </div>
      </Modal>
      <div className="container py-10">
        <div className="row relative">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="pro-thumbnail">Зураг</th>
                    <th className="pro-title">Барааны код</th>
                    <th className="pro-title">Нэр</th>
                    <th className="pro-price">Ширхэг үнэ</th>
                    <th className="pro-quantity">Тоо хэмжээ</th>
                    <th className="pro-subtotal">Нийт үнэ</th>
                    <th className="pro-remove">Хасах</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.basket?.length > 0 ? (
                    _.map(state?.basket, (item) => {
                      const total =
                        item?.price && item?.quantity
                          ? item?.price * item?.quantity
                          : 0;

                      const handleQuantityChange = (newQuantity) => {
                        if (newQuantity <= item?.stock) {
                          const updatedBasket = _.map(state?.basket, (el) => {
                            if (el?.public_id === item?.public_id) {
                              return { ...el, quantity: newQuantity };
                            } else {
                              return { ...el };
                            }
                          });
                          dispatch({
                            type: "STATE",
                            data: { basket: updatedBasket },
                          });
                          localStorage.setItem(
                            "basket_list",
                            JSON.stringify(updatedBasket)
                          );
                        } else {
                          alert("Үлдэгдэл хүрэлцэхгүй байна.");
                        }
                      };

                      const handleDecrement = () => {
                        const updatedQuantity =
                          item?.quantity !== 1
                            ? item?.quantity - 1
                            : item?.quantity;
                        handleQuantityChange(updatedQuantity);
                      };

                      const handleIncrement = () => {
                        const updatedQuantity =
                          item?.stock > item?.quantity
                            ? item?.quantity + 1
                            : item?.quantity;
                        handleQuantityChange(updatedQuantity);
                      };

                      const handleRemove = (e) => {
                        e.preventDefault();
                        const updatedBasket = _.filter(
                          state?.basket,
                          (el) => el?.public_id !== item?.public_id
                        );
                        dispatch({
                          type: "STATE",
                          data: { basket: updatedBasket },
                        });
                        localStorage.setItem(
                          "basket_list",
                          JSON.stringify(updatedBasket)
                        );
                      };

                      return (
                        <tr key={item?.public_id}>
                          <td className="pro-thumbnail flex items-center justify-center">
                            {item?.files[0] && (
                              <img
                                src={"https://" + item?.files[0]}
                                alt="product"
                                width={200}
                                height={200}
                                className="img-fluid w-[100px] h-[100px] object-contain"
                              />
                            )}
                          </td>
                          <td
                            className="align-middle text-center text-blue-700 cursor-pointer select-none"
                            onClick={() => {
                              navigate("/products/" + item?.public_id);
                            }}
                          >
                            {item?.code}
                          </td>
                          <td className="align-middle text-center">
                            {item?.title}
                          </td>
                          <td className="align-middle text-center">
                            {item?.price && item?.price?.toLocaleString()}₮
                          </td>
                          <td className="pro-quantity w-[150px] align-middle text-center">
                            <div className="quantity">
                              <div className="cart-plus-minus">
                                <input
                                  className="cart-plus-minus-box outline-none"
                                  type="text"
                                  value={item?.quantity}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                                <div
                                  className="dec qtybutton"
                                  onClick={handleDecrement}
                                >
                                  <i className="fa fa-minus"></i>
                                </div>
                                <div
                                  className="inc qtybutton"
                                  onClick={handleIncrement}
                                >
                                  <i className="fa fa-plus"></i>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            {total && total.toLocaleString()}₮
                          </td>
                          <td className="pro-remove align-middle text-center">
                            <a href="/" onClick={handleRemove}>
                              <i className="fa fa-trash text-gray-700 duration-200 hover:text-app-green" />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>Сагс хоосон байна</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {state?.basket?.length === 0 && (
                <div className="flex items-center justify-center mt-4">
                  <div
                    className="py-2 px-6 bg-app-green rounded-md text-white cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Дэлгүүр рүү буцах
                  </div>
                </div>
              )}
            </div>
            <div
              className={`items-center justify-end mt-8 ${
                state?.basket?.length > 0 ? "flex" : "hidden"
              }`}
            >
              <div className="bg-[#f8f8f8] w-full sm:w-[500px] font-medium">
                <div className="p-4">
                  <div className="text-lg">Сагсны нийт дүн</div>
                  <div className="border-b py-4 text-gray-600">
                    <div className="flex items-center justify-between">
                      <div>{payment_type === 2 ? "Барааны дүн" : "Нийт"}</div>
                      <div className="text-gray-900">
                        {total ? total.toLocaleString() : 0}₮
                      </div>
                    </div>
                    <div
                      className={
                        payment_type === 2
                          ? "flex items-center justify-between mt-2"
                          : "hidden"
                      }
                    >
                      <div>QPay үйлчилгээний хураамж</div>
                      <div className="text-gray-900">
                        {total
                          ? _.round(
                              (total * 10000) / 99 / 100 - total,
                              2
                            ).toLocaleString()
                          : 0}
                        ₮
                      </div>
                    </div>
                    <div
                      className={
                        payment_type === 2
                          ? "flex items-center justify-between mt-2"
                          : "hidden"
                      }
                    >
                      <div>Нийт</div>
                      <div className="text-gray-900">
                        {total
                          ? _.round(
                              (total * 10000) / 99 / 100,
                              2
                            ).toLocaleString()
                          : 0}
                        ₮
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">Төлбөрийн төрлөө сонгоно уу</div>
                  <div
                    className={`border p-2 flex items-center gap-2 rounded select-none cursor-pointer ${
                      state?.info?.is_company ? "my-3" : "mt-3"
                    } ${
                      payment_type === 1 ? "bg-app-green/80 text-white" : ""
                    }`}
                    onClick={() => {
                      setPayment_type(1);
                    }}
                  >
                    <PiWalletFill
                      size={40}
                      className={`fa fa-exchange p-2 border-2 rounded ${
                        payment_type === 1
                          ? "text-white border-gray-100"
                          : "text-app-green border-gray-300"
                      }`}
                    />
                    <div>
                      <div>Дансаар шилжүүлэх</div>
                      <div
                        className={`text-xs ${
                          payment_type === 1 ? "text-gray-200" : "text-gray-600"
                        } font-light`}
                      >
                        Аль ч банкны аппликейшн ашиглан шилжүүлэх боломжтой
                      </div>
                    </div>
                  </div>
                  {!state?.info?.is_company && (
                    <div
                      className={`border p-2 flex items-center gap-2 rounded select-none cursor-pointer my-3 ${
                        payment_type === 2 ? "bg-app-green/80 text-white" : ""
                      }`}
                      onClick={() => {
                        setPayment_type(2);
                      }}
                    >
                      <IoQrCodeOutline
                        size={40}
                        className={`fa fa-exchange p-2 border-2 rounded ${
                          payment_type === 2
                            ? "text-white border-gray-100"
                            : "text-app-green border-gray-300"
                        }`}
                      />
                      <div>
                        <div>QPay - ээр төлөх (+1%)</div>
                        <div
                          className={`text-xs ${
                            payment_type === 2
                              ? "text-gray-200"
                              : "text-gray-600"
                          } font-light`}
                        >
                          Аль ч банкны аппликейшн ашиглан төлөх боломжтой
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="w-full duration-300 bg-app-green/80 py-4 -mt-4 text-white text-center hover:bg-app-green hover:cursor-pointer"
                  onClick={() => {
                    if (state?.token) {
                      setState((old) => ({ ...old, modal: true }));
                    } else {
                      navigate("/login-register?redirect_url=/basket");
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  Худалдан авах
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Page);

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs } from "swiper/modules";
import React, { useState } from "react";
import _ from "lodash";
import { useAppContext } from "../contexts/GlobalContext";
import { Image } from "antd";

const ProductImages = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { dispatch } = useAppContext();

  return (
    <>
      <Swiper
        thumbs={{
          swiper:
            thumbsSwiper && !thumbsSwiper?.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2 w-full h-[300px] sm:h-[400px] md:h-[200px] lg:h-[300px] xl:h-[400px] 2xl:h-[450px]"
      >
        {_.map(data, (item, index) => {
          return (
            <SwiperSlide className="border w-full h-full" key={"a_" + index}>
              <Image
                src={"https://" + item}
                alt="product"
                className="w-full h-full object-cover"
                onClick={() => {
                  dispatch({
                    type: "STATE",
                    data: {
                      image_open: true,
                    },
                  });
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={20}
        slidesPerView={4}
        freeMode={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper mt-4 w-full h-14 sm:h-16 md:h-10 lg:h-16 xl:h-20"
      >
        {_.map(data, (item, index) => {
          return (
            <SwiperSlide className="border cursor-pointer" key={"b_" + index}>
              <img
                src={"https://" + item}
                alt="product"
                width={200}
                height={200}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default React.memo(ProductImages);

import { Alert, Modal } from "antd";
import React, { useEffect } from "react";
import { useAppContext } from "../contexts/GlobalContext";
import * as API from "../api/request";
import { toast } from "react-toastify";
import _ from "lodash";

const OrderModal = () => {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    if (
      !state?.info?.is_company &&
      state?.payment_type === 2 &&
      state?.order?.public_id
    ) {
      dispatch({
        type: "STATE",
        data: {
          loading: true,
        },
      });
      const qpay = localStorage.getItem("qpay");
      const qpay_json = JSON.parse(qpay);
      if (qpay_json && state?.order?.public_id === qpay_json?.order_id) {
        dispatch({
          type: "STATE",
          data: {
            qpay: qpay_json?.qpay,
            loading: false,
          },
        });
      } else {
        API.post("/qpay/invoice/create", {
          order_public_id: state?.order?.public_id,
        })
          .then((res) => {
            if (res?.status_code) {
              dispatch({
                type: "STATE",
                data: {
                  qpay: res?.qpay,
                },
              });
              localStorage.setItem("qpay", JSON.stringify(res));
            } else {
              toast.warn(res?.response);
            }
          })
          .catch((err) => {
            toast.warn(err?.response?.data?.message);
          })
          .finally(() => {
            dispatch({
              type: "STATE",
              data: {
                loading: false,
              },
            });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.info?.is_company, state?.order?.public_id, state?.payment_type]);

  const check = () => {
    dispatch({
      type: "STATE",
      data: {
        loading: true,
      },
    });
    API.post("/qpay/check", { qpay_payment_id: state?.qpay?.invoice_id })
      .then((res) => {
        if (res?.status_code) {
          toast.success(res?.message);
          localStorage.removeItem("qpay");
          dispatch({
            type: "STATE",
            data: {
              payment_modal: false,
              order: {},
              qpay: {},
              url_open: false,
            },
          });
        } else {
          toast.warn(res?.message);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      })
      .finally(() => {
        dispatch({
          type: "STATE",
          data: {
            loading: false,
          },
        });
      });
  };

  return (
    <Modal
      title="Төлбөр"
      open={state?.payment_modal}
      footer={false}
      onCancel={() => {
        dispatch({
          type: "STATE",
          data: {
            payment_modal: false,
            order: {},
            qpay: {},
            url_open: false,
          },
        });
      }}
      centered
    >
      <div>
        <Alert
          message="Та төлбөрөө 48 цагийн дотор төлснөөр таны захиалга баталгаажихыг анхаарна уу."
          type="warning"
          className="my-2"
        />
        <div
          className={`${
            state?.loading ? "absolute" : "hidden"
          } top-0 left-0 w-full h-full z-50 bg-black/30`}
        >
          <div className="flex items-center justify-center h-full text-white font-light">
            Уншиж байна...
          </div>
        </div>
        <div className="flex items-center justify-center p-1 bg-gray-200 rounded">
          <div
            className={`flex-1 flex items-center justify-center py-2 cursor-pointer ${
              state?.payment_type === 1 ? "rounded text-white bg-app-green" : ""
            }`}
            onClick={() => {
              dispatch({
                type: "STATE",
                data: {
                  payment_type: 1,
                },
              });
            }}
          >
            Данс
          </div>
          {!state?.info?.is_company && (
            <div
              className={`flex-1 flex items-center justify-center py-2 cursor-pointer ${
                state?.payment_type === 2
                  ? "rounded text-white bg-app-green"
                  : ""
              }`}
              onClick={() => {
                dispatch({
                  type: "STATE",
                  data: {
                    payment_type: 2,
                  },
                });
              }}
            >
              QPay
            </div>
          )}
        </div>
        {state?.payment_type === 1 && (
          <div className="w-full border p-4 rounded-md shadow-md my-4">
            <div className="bg-gray-50 p-3 rounded flex items-center justify-between">
              <div>
                <div className="text-sm text-gray-600">Хүлээн авах банк</div>
                <div className="font-bold text-lg">Банк нэр</div>
              </div>
            </div>
            <div className="bg-gray-50 p-3 rounded flex items-center justify-between mt-4">
              <div>
                <div className="text-sm text-gray-600">Хүлээн авах данс</div>
                <div className="font-bold text-lg">000 000 000</div>
              </div>
              <i className="fa fa-clone" />
            </div>
            <div className="bg-gray-50 p-3 rounded flex items-center justify-between mt-4">
              <div>
                <div className="text-sm text-gray-600">Хүлээн авагч</div>
                <div className="font-bold text-lg">Эрдэнэт үйлдвэр ТӨҮГ</div>
              </div>
              <i className="fa fa-clone" />
            </div>
            <div className="bg-gray-50 p-3 rounded flex items-center justify-between mt-4">
              <div>
                <div className="text-sm text-gray-600">Захиалгын дүн</div>
                <div className="font-bold text-lg">
                  {_.round(state?.order?.invoice?.price, 2).toLocaleString()} ₮
                </div>
              </div>
              <i className="fa fa-clone" />
            </div>
            <div className="bg-gray-50 p-3 rounded flex items-center justify-between mt-4">
              <div>
                <div className="text-sm text-gray-600">Гүйлгээний утга</div>
                <div className="font-bold text-lg">
                  {state?.order?.invoice?.invoice_id}
                </div>
              </div>
              <i className="fa fa-clone" />
            </div>
          </div>
        )}
        {state?.payment_type === 2 && (
          <div className="w-full border p-4 rounded-md shadow-md my-4">
            <div
              className="my-2 px-4 py-1.5 bg-app-green text-center text-white rounded cursor-pointer"
              onClick={() => {
                check();
              }}
            >
              Төлбөр шалгах
            </div>
            {state?.url_open ? (
              <div className="w-full h-full grid grid-cols-2 gap-2">
                {_.map(state?.qpay?.urls, (el) => {
                  return (
                    <a
                      href={el?.link}
                      className="flex items-center flex-col justify-center"
                    >
                      <img
                        src={el?.logo}
                        width={100}
                        height={100}
                        className="w-9 h-9 min-h-[36px] min-w-[36px] rounded-lg"
                        alt="qpay"
                      />
                      <div className="font-semibold text-center">
                        {el?.description}
                      </div>
                    </a>
                  );
                })}
              </div>
            ) : state?.qpay?.qr_image ? (
              <img
                src={`data:image/png;base64,${state?.qpay?.qr_image}`}
                alt="qr"
                className="w-full h-full object-contain"
              />
            ) : (
              <img
                src={`./img/loader.gif`}
                alt="qr"
                className="w-full h-full object-contain"
              />
            )}
            <div
              className="my-2 px-4 py-1.5 bg-app-green text-center text-white rounded cursor-pointer"
              onClick={() => {
                dispatch({
                  type: "STATE",
                  data: {
                    url_open: !state?.url_open,
                  },
                });
              }}
            >
              {state?.url_open ? "Хаах" : "Банкны апп нээх"}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrderModal;

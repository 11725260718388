import React, { useEffect, useState } from "react";
import { useAppContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const Quantity = ({ item }) => {
  let data = item;
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();
  const [quantity, setQuantity] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  var find_basket = state?.basket?.find(
    (el) => el?.public_id === data?.public_id
  );
  useEffect(() => {
    if (find_basket) {
      setQuantity(find_basket?.quantity);
    } else {
      setQuantity(1);
    }
  }, [find_basket]);

  const decrementQuantity = () => {
    if (parseInt(quantity) > 1) {
      setQuantity(quantity - 1);
      item.quantity = parseInt(quantity) - 1;
    } else {
      setErrorMessage("Хамгийн багадаа: 1");
    }
  };

  const incrementQuantity = () => {
    if (parseInt(quantity) + 1 <= item?.stock) {
      setQuantity(parseInt(quantity) + 1);
      item.quantity = parseInt(quantity) + 1;
    } else {
      setErrorMessage(`Боломжит хэмжээ: ${item?.stock}.`);
    }
  };

  const handleBasket = async (type) => {
    if (find_basket) {
      const updatedBasket = _.map(state?.basket, (basketItem) => {
        if (basketItem?.public_id === data.public_id) {
          return _.assign({}, basketItem, { quantity });
        }
        return basketItem;
      });
      await dispatch({
        type: "STATE",
        data: {
          basket: updatedBasket,
        },
      });
      localStorage.setItem("basket_list", JSON.stringify(updatedBasket));
      if (type === "purchase") {
        navigate("/basket");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      const updatedBasket = [...state?.basket, { ...item, quantity }];
      await dispatch({
        type: "STATE",
        data: {
          basket: updatedBasket,
        },
      });
      localStorage.setItem("basket_list", JSON.stringify(updatedBasket));
      if (type === "purchase") {
        navigate("/basket");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <div className="quantity mt-4 md:mt-6 select-none">
        <div className="cart-plus-minus">
          <input
            className="cart-plus-minus-box outline-none"
            type="text"
            value={quantity || ""}
            onChange={(e) => {
              const enteredValue = parseInt(e.target.value);
              if (!isNaN(enteredValue) && enteredValue >= 1) {
                if (enteredValue <= item?.stock) {
                  setQuantity(enteredValue);
                  setErrorMessage("");
                } else {
                  setQuantity(item?.stock);
                  setErrorMessage(`Боломжит хэмжээ: ${item?.stock}.`);
                }
              } else {
                setQuantity(1);
                setErrorMessage("Хамгийн багадаа: 1");
              }
            }}
          />
          <div className="dec qtybutton" onClick={decrementQuantity}>
            <i className="fa fa-minus" />
          </div>
          <div className="inc qtybutton" onClick={incrementQuantity}>
            <i className="fa fa-plus" />
          </div>
        </div>
        {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
      </div>
      <div className="flex items-center gap-4 mt-4 md:mt-6">
        <div
          className={`border ${
            find_basket
              ? "border-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white text-red-600"
              : "border-gray-600 hover:bg-app-green hover:border-app-green hover:text-white"
          } flex items-center justify-center px-5 py-2 rounded-md font-semibold duration-300 md:text-lg hover:cursor-pointer`}
          onClick={() => {
            if (find_basket) {
              const filteredBasket = _.filter(
                state?.basket,
                (el) => el?.public_id !== data?.public_id
              );
              dispatch({
                type: "STATE",
                data: {
                  basket: filteredBasket,
                },
              });
              localStorage.setItem(
                "basket_list",
                JSON.stringify(filteredBasket)
              );
            } else {
              handleBasket("basket");
            }
          }}
        >
          {find_basket ? "Сагснаас хасах" : "Сагслах"}
        </div>
        <div
          onClick={() => handleBasket("purchase")}
          className="border border-gray-600 flex items-center justify-center px-5 py-2 rounded-md font-semibold duration-300 md:text-lg hover:bg-app-green hover:border-app-green hover:text-white hover:cursor-pointer"
        >
          Худалдан авах
        </div>
      </div>
    </>
  );
};

export default React.memo(Quantity);

import React from "react";
import ReactDOM from "react-dom/client";
import "./tailwind.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import App from "./pages/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

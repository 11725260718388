import React, { useRef, useState } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { post } from "../api/request";

const Register = () => {
  const otp = useRef(null);
  const [state, setState] = useState({
    iscompany: false,
    show_password: false,
    submit: false,
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    register: "",
    otp_send: false,
    otp: "",
    loading: false,
  });

  const validateFields = () => {
    const { firstname, lastname, phone, password, register, iscompany } = state;
    if (iscompany) {
      return register && lastname && phone?.length === 8 && password;
    } else {
      return firstname && lastname && phone?.length === 8 && password;
    }
  };

  const otp_register = async () => {
    setState((old) => ({ ...old, submit: true }));
    if (validateFields()) {
      setState((old) => ({ ...old, loading: true }));
      try {
        const opt = await post("/user/otp/register", {
          phone: state?.phone,
        });
        if (opt?.status_code) {
          setState((old) => ({ ...old, otp_send: true }));
          setTimeout(() => {
            otp.current.focus();
          }, 100);
        } else {
          toast.warn(opt?.response);
        }
      } catch (error) {
        toast.warn(error?.message);
      } finally {
        setState((old) => ({ ...old, loading: false }));
      }
    }
  };

  const opt_verify = async () => {
    setState((old) => ({ ...old, submit: true }));
    if (validateFields()) {
      try {
        setState((old) => ({ ...old, loading: true }));
        const res = await post("/user/register", {
          email: "",
          firstname: state?.firstname,
          lastname: state?.lastname,
          otp: state?.otp,
          password: state?.password,
          phone: state?.phone,
          register: state?.register,
          is_company: state?.iscompany,
        });
        if (res?.status_code) {
          setState((old) => ({
            ...old,
            iscompany: false,
            show_password: false,
            submit: false,
            firstname: "",
            lastname: "",
            phone: "",
            password: "",
            otp_send: false,
            otp: "",
          }));

          toast.success("Бүртгэл амжилттай үүслээ. Та нэвтрэнэ үү.");
        } else {
          toast.warn(res?.response);
        }
      } catch (error) {
        toast.warn(error?.message);
      } finally {
        setState((old) => ({ ...old, loading: false }));
      }
    }
  };

  return (
    <>
      <div
        className={`${
          state?.otp_send ? "fixed" : "hidden"
        } top-0 left-0 w-screen h-screen bg-black/50 z-40`}
      />
      {state?.otp_send && (
        <i
          className="fa fa-close fixed top-5 right-5 z-50 text-white text-3xl duration-200 hover:text-app-green hover:cursor-pointer"
          onClick={() => {
            setState((old) => ({ ...old, otp_send: false }));
          }}
        />
      )}

      <div
        className={`${
          state?.otp_send ? "fixed" : "hidden"
        } top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-4`}
      >
        <div className="flex items-center justify-center">
          <input
            ref={otp}
            type="text"
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm ${
              !state?.otp && state?.submit ? "border-red-500" : ""
            }`}
            placeholder="Баталгаажуулах код"
            value={state?.otp}
            onChange={(e) => {
              const value = e.target.value;
              const reg = /^-?\d+$/;
              if (value === "" || (reg.test(value) && value.length <= 6)) {
                setState((old) => ({ ...old, otp: e.target.value }));
              }
            }}
          />
          <i
            className="fa fa-check-circle text-xl text-white py-1 px-3 border border-app-green bg-app-green duration-200 hover:bg-green-600 hover:cursor-pointer"
            onClick={() => opt_verify()}
          />
        </div>
      </div>
      <div className="bg-[#E8E8E8] flex items-center justify-center flex-col p-10 select-none">
        <div className="text-2xl md:text-3xl font-semibold text-gray-800">
          Бүртгүүлэх
        </div>
        <div className="text-sm text-center text-gray-600 font-light mt-2">
          Доорх мэдээллийг бөглөж бүртгэл үүсгэнэ үү.
        </div>
        <ul className="product-tab-nav nav justify-content-center title-border-bottom flex items-center justify-center">
          <li className="nav-item hover:cursor-pointer">
            <a
              href="/"
              className={`nav-link mt-3 text-center ${
                state?.iscompany ? "" : "active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setState((old) => ({ ...old, iscompany: false }));
              }}
            >
              Хувь хүн
            </a>
          </li>
          <li className="nav-item hover:cursor-pointer">
            <a
              href="/"
              className={`nav-link mt-3 text-center ${
                state?.iscompany ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setState((old) => ({ ...old, iscompany: true }));
              }}
            >
              Байгууллага
            </a>
          </li>
        </ul>
        <input
          type="text"
          className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
            state?.iscompany ? "hidden" : "block"
          } ${!state?.firstname && state?.submit ? "border-red-500" : ""}`}
          placeholder="Овог"
          value={state?.firstname}
          onChange={(e) => {
            setState((old) => ({ ...old, firstname: e.target.value }));
          }}
        />
        <input
          type="text"
          className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
            !state?.lastname && state?.submit ? "border-red-500" : ""
          }`}
          placeholder={state?.iscompany ? "Компани нэр" : "Нэр"}
          value={state?.lastname}
          onChange={(e) => {
            setState((old) => ({ ...old, lastname: e.target.value }));
          }}
        />
        <input
          type="text"
          className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
            state?.iscompany ? "block" : "hidden"
          } ${!state?.register && state?.submit ? "border-red-500" : ""}`}
          placeholder="Компанийн регистрийн дугаар"
          value={state?.register}
          onChange={(e) => {
            const value = e.target.value;
            const reg = /^-?\d+$/;
            if (value === "" || (reg.test(value) && value.length <= 6)) {
              setState((old) => ({ ...old, register: e.target.value }));
            }
          }}
        />
        <input
          type="text"
          className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
            state?.phone?.length !== 8 && state?.submit ? "border-red-500" : ""
          }`}
          placeholder="Утасны дугаар"
          value={state?.phone}
          onChange={(e) => {
            const value = e.target.value;
            const reg = /^-?\d+$/;
            if (value === "" || (reg.test(value) && value.length <= 8)) {
              setState((old) => ({ ...old, phone: e.target.value }));
            }
          }}
        />
        <div className="mt-4 w-full relative">
          <input
            type={state?.show_password ? "text" : "password"}
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full text-sm ${
              !state?.password && state?.submit ? "border-red-500" : ""
            }`}
            placeholder="Нууц үг оруулна уу"
            value={state?.password}
            onChange={(e) => {
              setState((old) => ({ ...old, password: e.target.value }));
            }}
          />
          <RiEyeCloseLine
            className={`absolute right-2 top-1/2 -translate-y-1/2 text-xs w-5 h-5 duration-300 hover:cursor-pointer ${
              state?.show_password && "text-app-green"
            }`}
            onChange={(e) => {
              setState((old) => ({
                ...old,
                show_password: !state?.show_password,
              }));
            }}
          />
        </div>
        <div
          className="px-4 mt-6 py-2.5 font-semibold self-end select-none bg-gray-900 text-white duration-300 hover:bg-app-green hover:cursor-pointer"
          onClick={() => {
            otp_register();
          }}
        >
          Бүртгүүлэх
        </div>
      </div>
    </>
  );
};

export default React.memo(Register);

import React from "react";
import Item from "../components/Item";
import SidebarFilter from "../components/SidebarFilter";
import Toolbar from "../components/Toolbar";
import _ from "lodash";
import { Spin } from "antd";
import { useAppContext } from "../contexts/GlobalContext";

const Product = () => {
  const { state, dispatch } = useAppContext();

  return (
    <section className="max-w-8xl mx-auto px-2 mt-28 sm:px-8 2xl:px-0">
      <div className="grid grid-cols-1 md:grid-cols-4 md:gap-8">
        <div className="col-span-1">
          <SidebarFilter />
        </div>
        <div className="col-span-3">
          <Toolbar length={state?.list?.length} />
          <Spin tip="Уншиж байна..." spinning={state?.loading}>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-10">
              {_.map(state?.list, (item, index) => {
                return <Item data={item} key={"products_" + index} />;
              })}
            </div>
          </Spin>
          {state?.page_count > 1 && (
            <div className="flex items-center justify-center mb-10">
              <div
                className="bg-app-green text-white px-6 py-2 rounded font-medium cursor-pointer duration-300 hover:scale-105"
                onClick={() => {
                  if (state?.page_count > 1) {
                    dispatch({
                      type: "STATE",
                      data: {
                        per_page: state?.per_page + 24,
                      },
                    });
                  }
                }}
              >
                Цааш үзэх
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(Product);

import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as API from "../api/request";
import { DatePicker, Modal } from "antd";
import { toast } from "react-toastify";
import { useAppContext } from "../contexts/GlobalContext";
import OrderModal from "./OrderModal";

const ProfileOrder = ({ orders, setRefresh, refresh }) => {
  const { dispatch } = useAppContext();
  const [state, setState] = useState({
    order_id: null,
    modal: false,
    submit: false,
    car_mark: "",
    car_number: "",
    request_date: null,
    request: [],
  });

  useEffect(() => {
    const find = _.find(orders, (el) => el.public_id === state?.order_id);
    if (find) {
      setState((old) => ({
        ...old,
        request: find?.request,
      }));
    }
  }, [orders, state?.order_id]);

  const save = async () => {
    setState((old) => ({ ...old, submit: true }));
    if (state?.car_mark && state?.car_number && state?.order_id) {
      const res = await API.post(`/user/orders/${state?.order_id}/request`, {
        car_mark: state?.car_mark,
        car_number: state?.car_number,
        request_date: moment(state?.request_date?.$d).format("YYYYMMDD"),
      });
      if (res?.status_code) {
        setRefresh(refresh + 1);
        setState((old) => ({
          ...old,
          submit: false,
          car_mark: "",
          car_number: "",
          request_date: null,
        }));

        toast.success(res?.message);
      } else {
        toast.warn(res?.response);
      }
    }
  };
  // moment(date?.$d).format("YYYYMMDD")
  const onChange = (date, dateString) => {
    setState((old) => ({
      ...old,
      request_date: date,
    }));
  };

  return (
    <div>
      <OrderModal />
      <Modal
        title="Тээврийн хэрэгслийн мэдээлэл"
        open={state?.modal}
        footer={false}
        onCancel={() => {
          setState((old) => ({ ...old, modal: false }));
        }}
        centered
      >
        <div>
          <input
            type="text"
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
              !state?.car_mark && state?.submit ? "border-red-500" : ""
            }`}
            placeholder="Машины марк"
            value={state?.car_mark}
            onChange={(e) => {
              setState((old) => ({ ...old, car_mark: e.target.value }));
            }}
          />
          <input
            type="text"
            className={`outline-none p-2 border duration-300 focus:border-app-green w-full mt-4 text-sm ${
              !state?.car_number && state?.submit ? "border-red-500" : ""
            }`}
            placeholder="Машины дугаар"
            value={state?.car_number}
            onChange={(e) => {
              setState((old) => ({ ...old, car_number: e.target.value }));
            }}
          />
          <DatePicker
            value={state?.request_date}
            onChange={onChange}
            format={"YYYY.MM.DD"}
            className="mt-4 w-full rounded-none text-gray-700 hover:border-gray-200"
            placeholder="Очих өдөр сонгох"
          />
          <div className="mt-4">
            <div
              className="px-4 py-1.5 h-full flex items-center rounded justify-center text-sm font-light select-none bg-app-green/80 text-white duration-300 hover:bg-app-green hover:cursor-pointer tracking-wider"
              onClick={() => {
                save();
              }}
            >
              Хадгалах
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered text-sm mt-3">
              <thead>
                <tr>
                  <th className="pro-title">Огноо</th>
                  <th className="pro-title">Машины марк</th>
                  <th className="pro-title">Машины дугаар</th>
                  <th className="pro-title">Устгах</th>
                </tr>
              </thead>
              <tbody>
                {_.map(
                  _.orderBy(state?.request, ["created_date"], ["desc"]),
                  (el) => {
                    return (
                      <tr
                        className="text-gray-700 font-normal text-sm"
                        key={el?.public_id}
                      >
                        <td>
                          {moment(el?.request_date).utc().format("YYYY.MM.DD")}
                        </td>
                        <td>{el?.car_mark}</td>
                        <td>{el?.car_number}</td>
                        <td className="align-middle text-center">
                          <i
                            className="fa fa-trash duration-200 cursor-pointer hover:text-red-600"
                            onClick={async () => {
                              try {
                                const res = await API.trash(
                                  `/user/orders/${el?.public_id}/request`
                                );
                                if (res?.status_code) {
                                  setRefresh(refresh + 1);
                                  toast.success("Амжилттай устгалаа");
                                } else {
                                  toast.warn(res?.message);
                                }
                              } catch (error) {
                                toast.warn(error?.response?.data?.message);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <div className="table-responsive">
        <table className="table table-bordered text-sm mt-3">
          <thead>
            <tr>
              <th className="pro-title">Захиалга хийсэн огноо</th>
              <th className="pro-title">Захиалгын дугаар</th>
              <th className="pro-title">Бараа</th>
              <th className="pro-title">Нийт үнэ</th>
              <th className="pro-title">Төлбөр</th>
              <th className="pro-title">Тээврийн хэрэгсэл</th>
            </tr>
          </thead>
          <tbody>
            {_.map(_.orderBy(orders, ["created_date"], ["desc"]), (el) => {
              if (el?.details?.length > 0) {
                let sum = _.sumBy(el?.details, (o) => {
                  return o.unit_price * o.user_quantity;
                });
                return (
                  <React.Fragment key={"child_" + el?.public_id}>
                    <tr className="text-gray-700 font-normal text-sm">
                      <td
                        rowSpan={el?.details?.length}
                        className="align-middle p-1"
                      >
                        {moment(el?.created_date)
                          .utc()
                          .format("YYYY.MM.DD HH:mm")}
                      </td>
                      <td
                        rowSpan={el?.details?.length}
                        className="align-middle text-center font-bold p-1"
                        onClick={() => {}}
                      >
                        {el?.invoice?.invoice_id}
                      </td>
                      <td className="align-middle text-xs lowercase p-0 m-0">
                        {el?.details[0]?.title}
                      </td>
                      <td
                        rowSpan={el?.details?.length}
                        className="text-center align-middle p-1"
                      >
                        {sum?.toLocaleString()}₮
                      </td>
                      <td
                        rowSpan={el?.details?.length}
                        className={`text-center align-middle text-yellow-600`}
                      >
                        <div
                          className={`px-4 py-1.5 rounded text-white duration-200 cursor-pointer ${
                            el?.invoice?.paid
                              ? "bg-app-green hover:bg-green-900"
                              : "bg-blue-600 hover:bg-blue-700"
                          }  text-xs`}
                          onClick={() => {
                            if (!el?.invoice?.paid) {
                              dispatch({
                                type: "STATE",
                                data: {
                                  payment_modal: true,
                                  order: el,
                                },
                              });
                            }
                          }}
                        >
                          {el?.invoice?.paid
                            ? "Төлбөр төлөгдсөн"
                            : "Төлбөр төлөх"}
                        </div>
                      </td>
                      <td
                        rowSpan={el?.details?.length}
                        className="text-center align-middle p-1"
                      >
                        <span
                          className="underline cursor-pointer"
                          onClick={() => {
                            setState((old) => ({
                              ...old,
                              modal: true,
                              request: el?.request,
                              order_id: el?.public_id,
                            }));
                          }}
                        >
                          Машин нэмэх
                        </span>
                      </td>
                    </tr>
                    {_.map(el?.details, (item, index) => {
                      if (index !== 0) {
                        return (
                          <tr
                            key={"child_" + item?.public_id}
                            className="text-gray-700 font-normal text-xs lowercase align-middle p-0 m-0"
                          >
                            <td>{item?.title}</td>
                          </tr>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(ProfileOrder);
